import * as React from 'react'
import { graphql } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import Layout from '../../components/layout'
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const BlogPost = ({ data }) => {
    const image = getImage(data.mdx.frontmatter.hero_image)
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    return (
        <Layout pageTitle={data.mdx.frontmatter.title}>
          <Container maxWidth="md">
            <Paper elevation={3}>
              <Box p={isMobile ? 2 : 10}>
                <Typography variant="h4">
                  {data.mdx.frontmatter.title}
                </Typography>
                <Divider />
                <Box p={2}></Box>
                <Box align="center">
                  <GatsbyImage
                      image={image}
                      alt={data.mdx.frontmatter.hero_image_alt}
                  />
                </Box>
                <Box align="center">
                  <Typography variant="caption">
                    <span>Photo by <a href={data.mdx.frontmatter.hero_image_credit_link} target="_blank" >{data.mdx.frontmatter.hero_image_credit_text}</a></span>
                  </Typography>
                </Box>
                <Box p={2}></Box>
                <Box >
                  <Typography align="justify" paragraph="true">
                    <MDXRenderer>
                      {data.mdx.body}
                    </MDXRenderer>
                  </Typography>
                </Box>
              </Box>
            </Paper>
          </Container>  
        </Layout>
    )
}

export const query = graphql`
  query($slug: String) {
    mdx(slug: {eq: $slug}) {
      body
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        hero_image_alt
        hero_image_credit_link
        hero_image_credit_text
        hero_image {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
`

export default BlogPost
